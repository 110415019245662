import React, { Component } from 'react'
import { navigate } from 'gatsby'
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  CardText,
  CardFooter,
} from 'reactstrap'
import redirectPhoto from '../assets/img/redirect.jpeg'

const numberStrings = ['5', '4', '3', '2', '1']

class Redirect extends Component {
  state = {
    number: 0,
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState(prevState => ({
        number: prevState.number + 1,
      }))
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  render() {
    if (this.state.number === 5) {
      navigate('/')
    }

    return (
      <div
        className="redirect-wrapper"
        style={{ backgroundImage: `url(${redirectPhoto})` }}
      >
        <div className="redirect-container">
          <section className="redirect-container-item">
            <Card>
              <CardBody>
                <CardTitle>Thanks for your submission</CardTitle>
                <CardSubtitle> </CardSubtitle>
                <CardText>We will be in touch soon! </CardText>
              </CardBody>
              <CardFooter>
                This page is reirecting in {numberStrings[this.state.number]}{' '}
                seconds
              </CardFooter>
            </Card>
          </section>
        </div>
      </div>
    )
  }
}

export default Redirect
